import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let
        tab1 = "Main skills",
        tab2 = "Interships",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <div className="tab-header">Web Development</div>
                                                    Javascript, HTML, CSS
                                                </li>
                                                <li>
                                                    <div className="tab-header">Application Development</div>
                                                    React, Ruby on Rails
                                                </li>
                                                <li>
                                                    <div className="tab-header">User Interface/Experience Design</div>
                                                    UI/UX
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <div className="tab-header">Software Engineer<span> - Birdie World</span></div> 2020
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <div className="tab-header">Software Engineer<span> - Birdie World</span></div> 2020
                                               </li>
                                               <li>
                                                   <div className="tab-header">Front-end Developer<span> - OpsTel</span></div> 2021
                                               </li>
                                               <li>
                                                   <div className="tab-header">Application Developer<span> - Capital One </span></div> 2021 - Present
                                               </li>
                                               <li>
                                                   <div className="tab-header">Help Desk Technician<span> - US Air Force </span></div> 2018 - 2022
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <div className="tab-header">Bachelors Degree in Software Development<span> - ECPI University</span></div> 2020
                                               </li>
                                               <li>
                                                   <div className="tab-header">Security+ Certification<span> - COMPTIA</span></div> 2018
                                               </li>
                                               <li>
                                                   <div className="tab-header">Business Process Management Certification<span> </span></div> 2022
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;