import React ,{ Component }from "react";
import {AiOutlineCode, AiOutlineMobile, AiOutlineTeam} from "react-icons/ai";
import {HiOutlineDocumentReport} from "react-icons/hi";
import {VscTasklist, VscDebugConsole} from "react-icons/vsc";

const ServiceList = [
    {
        icon: <AiOutlineCode />,
        title: 'Website Development',
        description: 'Think of all things web development. Ive got you covered.'
    },
    {
        icon: <AiOutlineMobile />,
        title: 'Mobile App Development',
        description: 'All things mobile app development.'
    },
    {
        icon: <VscDebugConsole />,
        title: 'Troubleshooting & Maintenance',
        description: 'Did somebody say updates… or somethings broken? '
    },
    {
        icon: <HiOutlineDocumentReport />,
        title: 'Reporting',
        description: 'Want to know how things are going? I got your back.'
    },
    {
        icon: <VscTasklist />,
        title: 'Planning & Organization',
        description: 'Big picture. Clear timelines. Its wonderful. '
    },
    {
        icon: <AiOutlineTeam />,
        title: 'Collaboration',
        description: 'Nerding out with teammates? Yes please. '
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
