import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import {Link} from "react-router-dom";
import { Image } from 'primereact/image';

class PortfolioOne extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--50 pb--100 bg_image bg_image--36"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">PROJECT DETAILS</h2>
                                    <p>You need the details to get the big picture</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>Birdie World</h2>
                                        <p className="subtitle">Social media website where you can rate entertainment, add entertainment to a watch list, and view what others are watching.</p>
                                        <p>Keywords: Ruby, Rails, CSS, Docker, Heroku</p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h4>Web Application</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Framework Used</span>
                                                <h4>Ruby on Rails</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>View</span>
                                                <h4><Link to={{ pathname: "https://www.birdieworld.com/" }} target="_blank">See Project</Link></h4>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="portfolio-thumb-inner row justify-content-center">
                                        <div className="col-lg-8 thumb mb--30">
                                            <Image src="/assets/images/portfolio/birdie-1.png" alt="Portfolio Images" preview/>
                                        </div>

                                        <div className="col-lg-8 thumb mb--30">
                                            <Image src="/assets/images/portfolio/birdie-2.png" alt="Portfolio Images" preview/>
                                        </div>

                                        <div className="col-lg-8 thumb">
                                            <Image src="/assets/images/portfolio/birdie-3.png" alt="Portfolio Images" preview/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default PortfolioOne;
