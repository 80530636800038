// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";

import PageScrollTop from './component/PageScrollTop';

// Dark Home Layout
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import PortfolioOne from "./elements/PortfolioOne";
import PortfolioTwo from "./elements/PortfolioTwo";
import PortfolioThree from "./elements/PortfolioThree";
import PortfolioFour from "./elements/PortfolioFour";
import PortfolioFive from "./elements/PortfolioFive";
import PortfolioSix from "./elements/PortfolioSix";
import error404 from "./elements/error404";


// Blocks Layout
import ProgressBar from "./blocks/ProgressBar";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding}/>


                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-1`} component={PortfolioOne}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-2`} component={PortfolioTwo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-3`} component={PortfolioThree}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-4`} component={PortfolioFour}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-5`} component={PortfolioFive}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-6`} component={PortfolioSix}/>

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>

                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();