import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Image } from 'primereact/image';

class PortfolioFive extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Portfolio Details' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--50 pb--100 bg_image bg_image--36"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">PROJECT DETAILS</h2>
                                    <p>You need the details to get the big picture</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                    <h2>Case per Hour Calculator</h2>
                                        <p className="subtitle">Tool to calculate an employee's case completion rate. This is calculated to assist in scoring their monthly ratings. The purpose of this tool is to advise how many cases an employee would need to complete in order to achieve a certain rank based on existing data. Other variations of this formula can be used as well.</p>
                                        <p>Features: Form intake, Bootstrap, dropdown menu, tooltips, pop-over reference guide, same-page results, and a reference table. <br/>
                                        <i>*Due to confidentiality, this project can only be viewed by way of screenshots.</i></p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h4>Web Application</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Framework Used</span>
                                                <h4>Google Scripting</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Keywords</span>
                                                <h4>Javascript, HTML, CSS</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner row justify-content-center">
                                      <div className="col-lg-5 thumb mb--30 mr--20">
                                          <Image src="/assets/images/portfolio/iph-calc-1.png" alt="Portfolio Images" preview/>
                                      </div>

                                      <div className="col-lg-5 thumb mb--30 mr--20">
                                          <Image src="/assets/images/portfolio/iph-calc-2.png" alt="Portfolio Images" preview/>
                                      </div>

                                      <div className="col-lg-5 thumb mb--30 mr--20">
                                          <Image src="/assets/images/portfolio/iph-calc-3.png" alt="Portfolio Images" preview/>
                                      </div>

                                      <div className="col-lg-5 thumb mb--30 mr--20">
                                          <Image src="/assets/images/portfolio/iph-calc-4.png" alt="Portfolio Images" preview/>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default PortfolioFive;
