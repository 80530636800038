import React, { Component } from "react";
import {BsPhone, BsArrowBarRight} from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";

class ContactThree extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-center justify-content-center">
                        <div className="col-lg-5 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">
                                    Help me help you! Connect with me:<br></br>
                                    <BsPhone /><BsArrowBarRight /><a href="tel:+18046299393"> 804.629.9393</a><br></br>
                                    <HiOutlineMail /><BsArrowBarRight /><a href="mailto:anjaeo93@gmail.com"> anjaeo93@gmail.com</a>
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-5 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;