import React, { Component }  from "react";

class BlogContent extends Component{
  render(){
    return(
      <iframe title="Chicken Game" seamless="" allowtransparency="true" allowFullScreen="" src="https://zv1y2i8p.play.gamezop.com/g/rJ57aMJDcJm" style={{width: 400, height: 300}}> </iframe>
    )
  }
}

export default BlogContent;