import React, { Component } from "react";
import {FaGithub ,FaInstagram, FaLinkedinIn, FaHandshake} from "react-icons/fa";
import {GiMuscleUp} from "react-icons/gi";

const SocialShare = [
  {Social: <FaGithub /> , link: 'https://www.github.com/anjaeo'},
  {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/in/anjaeo/'},
  {Social: <FaInstagram /> , link: 'https://www.instagram.com/projectearth.22'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Ready To Do This?</span>
                                        <h2>Let's work! <GiMuscleUp /></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">

                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello <FaHandshake /></h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:anjaeo93@gmail.com">anjaeo93@gmail.com</a></li>
                                                    <li><a href="tel:+18046299393">804.629.9393</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2023 Project Earth. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;